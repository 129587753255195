<template>
  <v-app class="all-app" id="app">
    <router-link class="logo" to="/">Cortez Black</router-link>
    <div id="nav" v-if="!showMobile">
      <router-link id="link" to="/blog">Blog</router-link>
      <!-- <router-link id="link" to="/services">Services</router-link> -->
      <router-link id="link" to="/startup">Startup</router-link>
      <router-link id="link" to="/post/details/blogs/MsVs1QuCUY9yiwv2gSds"
        >About</router-link
      >
    </div>
    <div id="nav" v-if="showMobile">
      <v-btn icon color="white" @click="overlay = !overlay">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <div class="links-container" v-if="!showMobile">
      <ul>
        <li>
          <a class="reg" target="_blank" href="https://www.linkedin.com/in/cortezblack/"
            >Linkedin •</a
          >
        </li>
        <li>
          <a class="reg" target="_blank" href="https://www.instagram.com/cortezblack_/"
            >Instagram •</a
          >
        </li>
        <li>
          <a class="reg" href="mailto:cortezblack.dev@gmail.com">Email •</a>
        </li>
        <li class="cal">
          <a
            class="calendar-link"
            target="_blank"
            href="https://calendly.com/cortezblack/30min"
            >Book a call
          </a>
        </li>
      </ul>
    </div>
    <v-overlay
      z-index="99999"
      :absolute="absolute"
      :opacity="opacity"
      :value="overlay"
      @click="overlay = false"
    >
      <v-btn class="close-button" color="orange lighten-2" icon @click="overlay = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="listed" @click="overlay = false">
        <router-link id="link" to="/">Home</router-link>
        <router-link id="link" to="/blog">Blog</router-link>
        <!-- <router-link id="link" to="/services">Services</router-link> -->
        <router-link id="link" to="/startup">Startup</router-link>
        <router-link id="link" to="/post/details/blogs/iayPdahIjNyNTY8Abcii"
          >About</router-link
        >
        <a id="link" target="_blank" href="https://www.linkedin.com/in/cortezblack/"
          >Linkedin
        </a>
        <a id="link" target="_blank" href="https://www.instagram.com/cortezblack_/"
          >Instagram
        </a>
        <a id="link" href="mailto:cortezblack.dev@gmail.com">Email </a>
        <a id="link" target="_blank" href="https://calendly.com/cortezblack/30min"
          >Book a call
        </a>
      </div>
    </v-overlay>
    <router-view />
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      showMobile: false,
      absolute: false,
      opacity: 1,
      overlay: false,
    };
  },
  mounted() {
    console.log('mounted');
    const mediaQuery = window.matchMedia('(max-width: 420px)');
    if (mediaQuery.matches) {
      this.showMobile = true;
    }
    console.log(this.showMobile);
  },
  methods: {},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Yatra+One&display=swap');
body {
  margin: 0;
  background-color: black;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: black;
  height: 100%;
  min-height: 100vh;
  .theme--light.v-icon {
    color: unset !important;
  }
  .links-container {
    position: fixed;
    right: -100px;
    z-index: 1;
    font-size: 1.125rem;
    top: 40%;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 550;
    color: #fff;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    ul {
      display: inline;
      list-style: none;
    }
    li {
      margin-left: 10px;
      display: inline;
      .reg {
        color: #fff;
        text-decoration: none;
        font-family: 'Nunito Sans', sans-serif;
      }
    }
    // transform-origin: right;
  }
  .calendar-link,
  .cal {
    text-decoration: underline;
    color: #d07919 !important;
    font-family: 'Ubuntu', sans-serif;
  }
  .logo {
    top: 30px;
    left: 10%;
    font-family: 'Yatra One', cursive;
    font-size: 1.25rem;
    color: #fff;
    position: absolute;
    text-decoration: none;
    z-index: 999;
  }

  .listed {
    display: flex;
    flex-direction: column;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.125rem;

    #link {
      margin-top: 20px;
      color: #fff;
    }

    a {
      font-weight: bold;
      color: #fff;
      letter-spacing: 0.5px;
      text-decoration: none;

      &.router-link-exact-active {
        text-decoration: line-through;
        color: #2c3e50;
      }
    }
  }

  #nav {
    // font-family: 'Ubuntu', sans-serif;
    // font-family: 'Yatra One', cursive;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.125rem;
    position: absolute;
    top: 30px;
    right: 10%;
    z-index: 99999;

    #link {
      margin-left: 20px;
    }

    a {
      font-weight: bold;
      color: #fff;
      letter-spacing: 0.5px;
      text-decoration: none;

      &.router-link-exact-active {
        text-decoration: line-through;
        color: #2c3e50;
      }
    }
    .v-overlay__content {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .overlay {
      height: 100%;
      width: 100%;
    }
    .close-button {
      position: absolute;
      top: 10;
      right: 10;
    }
  }
}
@media only screen and (min-device-width: 350px) and (max-device-width: 736px) {
  #app {
    #nav {
      display: flex;
      flex-direction: column;
      text-align: right;
      font-size: 1rem;
      #link {
        margin-top: 5px;
      }
      a {
        font-weight: 500;
        color: #d07919;

        &.router-link-exact-active {
          text-decoration: line-through;
          color: #2c3e50;
        }
      }
    }
    .logo {
      font-family: 'Yatra One', cursive;
      font-size: 1.25rem;
      left: 5%;
      top: 35px;
    }
  }
}
</style>
