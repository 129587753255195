<template>
  <div class="home">
    <div class="main-container">
      <div class="headline-wrapper">
        <div class="headline">I help brands launch and scale their products online.</div>
        <div class="bio-text">
          Hi, I'm Cortez. A software developer, architect, and design enthusiast creating
          applications that have impact. I write about creating
          <span @click="gotoStartup" class="link">Startup's here</span>, scaling, design
          and everything tech.
        </div>
        <div class="get-in-touch">
          <a
            class="calendar-link"
            target="_blank"
            href="https://calendly.com/cortezblack/30min"
            ><button @click="overlay = true">Get In Touch</button></a
          >
        </div>
      </div>
      <div class="container">
        <img :lazy-src="nasa" :src="brain" alt="brain" class="the-brain" />
        <div class="brain-overlay"></div>
      </div>
      <div class="second-wrapper">
        <div class="headline underline">What I Do</div>
        <div class="what-text">
          I like to build apps and over the years that hasn't changed much. I want to
          share my knowledge with others & help transform businesses and brands who want
          to take their products to the next level using:
        </div>
      </div>
      <div class="bio-container">
        <div class="block">
          <div class="block-title">Design</div>
          <div class="block-text">
            User centered design is at the forefront of building products that matter. I
            use design thinking to build products consumers want based on the optimal
            experience for them.
          </div>
        </div>
        <div class="block">
          <div class="block-title">Infrastructure & Architecture</div>
          <div class="block-text">
            Whether it's a Shopify store, building a web application, or migrating to the
            cloud - evaluating infrastructure and ensuring the tools and technologies you
            use are efficient and scalable.
          </div>
        </div>
        <div class="block">
          <div class="block-title">Strategy</div>
          <div class="block-text">
            Roadmaps can be daunting. Understanding how technology affects and integrates
            with your business processes is key to building a scalable solution.
          </div>
        </div>
      </div>

      <div class="ican-container"><span class="ican">I can help?</span></div>
      <div class="last-wrapper">
        <v-card elevation="2" :rounded="false" class="help-block" @click="openCal">
          <img :src="code" alt="light" class="top-image" />
          <div class="main">
            <div class="subheader">Build an app</div>
            <div class="help-text">
              Need a developer to build out your idea? Email me with details or book a
              slot to discuss a plan.
            </div>
          </div>
        </v-card>
        <!-- block -->
        <v-card elevation="2" class="help-block" @click="openCal">
          <img :src="design" alt="launch" class="top-image" />
          <div class="main">
            <div class="subheader">Design</div>
            <div class="help-text">
              UX & UI is essential in building apps that your consumers want. So this is
              the stage that needs to be prioritized
            </div>
          </div>
        </v-card>
        <!-- block -->
        <v-card elevation="2" class="help-block" @click="openCal">
          <img :src="mvp" alt="code" class="top-image" />
          <div class="main">
            <div class="subheader">MVP</div>
            <div class="help-text">
              Testing your idea to ensure Product market fit is essential. I can help you
              go from idea to market to attract early adopters of your products.
            </div>
          </div>
        </v-card>
        <!-- block -->
        <v-card elevation="2" class="help-block" @click="openCal">
          <img :src="launch" alt="launch" class="top-image" />
          <div class="main">
            <div class="subheader">Launch & Scale</div>
            <div class="help-text">
              Launch and scale your product utilizing these stages: design,
              infrastructure, and architecture.
            </div>
          </div>
        </v-card>
        <!-- block -->
        <v-card elevation="2" class="help-block" @click="openCal">
          <img :src="light" alt="code" class="top-image" />
          <div class="main">
            <div class="subheader">Learn to code</div>
            <div class="help-text">
              Become a developer? Enhance your skills? Let's chat and I can help you plan
              a roadmap to get you to where you want to be.
            </div>
          </div>
        </v-card>
        <v-card elevation="2" class="help-block" @click="openCal">
          <img :src="mentor" alt="light" class="top-image" />
          <div class="main">
            <div class="subheader">Chat/Mentorship</div>
            <div class="help-text">
              Let's chat! If you need help, want to talk career paths, cloud? I love
              meeting new people, hearing new ideas.
            </div>
          </div>
        </v-card>
      </div>
      <!--Image overlay - underlay -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { BIO } from '@/configs/static_text.js';

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      nasa: require('../assets/nasa.jpg'),
      brain: require('../assets/space-min.png'),
      code: require('../assets/code.png'),
      launch: require('../assets/project.png'),
      light: require('../assets/know.png'),
      mentor: require('../assets/mentor.png'),
      design: require('../assets/design.png'),
      mvp: require('../assets/mvp.png'),
      bio: BIO,
      titles: ['developer', 'architect', 'engineer', 'enthusiast'],
      currentTitle: 'developer',
    };
  },
  created() {
    this.$store.dispatch('getBlogs');
    // let repeater = 0;
    // for (repeater = 0; repeater < this.titles.length; repeater++) {
    //   this.currentTitle = this.titles[repeater];
    //   if (repeater == 3) {
    //     repeater = 0;
    //   }
    // }
  },
  methods: {
    gotoStartup() {
      this.$router.push('/startup');
    },
    changeLabel() {
      return this.titles[0];
    },
    openCal() {
      console.log('clicked item');
    },
  },
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;600&family=Noto+Sans:ital@0;1&family=Nunito+Sans:wght@600&family=Ubuntu:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
* {
  font-family: 'Cardo', serif;
}
.v-application .headline {
  font-size: unset !important;
  font-weight: unset;
  line-height: unset;
  letter-spacing: unset;
  font-family: unset !important;
}
.home {
  position: relative;
  padding-bottom: 25px;
}
.main-container {
  position: relative;
  min-height: fit-content;
  // width: 100%;
  margin: 0 10% 100px 10%;
  .headline {
    font-size: 3rem !important;
    color: #fff;
    font-weight: bold;
    text-align: left;
    max-width: 50%;
    font-family: 'Cardo', serif;
  }
}
.headline-wrapper {
  position: relative;
  margin-top: 16%;
  z-index: 999;
  width: 100%;
  .headline {
    font-size: 3rem !important;
    color: #fff;
    font-weight: bold;
    text-align: left;
    max-width: 50%;
    font-family: 'Cardo', serif;
  }
}
.second-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 150px;
  z-index: 999;
  margin-bottom: 50px;

  .what-text {
    width: 70%;
    font-family: 'DM Sans', sans-serif;
    font-size: 1.25rem;
    line-height: 150%;
    margin-top: 20px;
    text-align: center;
    color: #fff;
  }
}
.underline {
  text-decoration: underline;
}
.bio-container {
  z-index: 999;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-evenly;
  width: 100%;
  margin-bottom: 125px;
  .block {
    width: 30%;
    margin: 40px 10px 10px 10px;
    z-index: 999;
    .block-title {
      font-size: 1.25rem;
      text-align: left;
      font-weight: 500;
      width: 100%;
      z-index: 999;
      color: #9c9c9c;
      font-family: 'DM Sans', sans-serif;
    }
    .block-text {
      z-index: 999;
      width: 100%;
      font-family: 'DM Sans', sans-serif;
      line-height: 150%;
      margin-top: 10px;
      text-align: left;
      font-size: 1.25rem;
      color: #fff;
      .calendar-link {
        text-decoration: underline;
        color: #d07919;
        font-family: 'Ubuntu', sans-serif;
      }
    }
  }
}

.calendar-link,
.cal {
  text-decoration: underline;
  color: #d07919;
  font-family: 'Ubuntu', sans-serif;
}
.ican-container {
  position: relative;
  display: flex;
  z-index: 999;
}
.ican {
  font-size: 3rem;
  color: #fff;
  font-weight: bold;
  text-align: left;
  max-width: 60%;
}
.ican:before {
  content: '';
  position: absolute;
  width: 15%;
  height: 1px;
  bottom: 0;
  left: 0%;
  border-bottom: 5px solid #d07919;
}
.last-wrapper {
  z-index: 999;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .ican:before {
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 0%;
    border-bottom: 1px solid red;
  }
  .help-block:hover {
    box-shadow: 2px 2px 15px 1px #d8d4d4;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
  .help-block {
    width: 25%;
    margin: 40px 5px 5px 5px;
    // background-color: #1a1a1a;
    background-color: #0e0e0e;
    box-shadow: 2px 2px 15px 1px #d8d4d4;
    border-radius: 5px;
    z-index: 999;
    padding: 10px;
    .top-image {
      // width: 100%;
      height: 75px;
      border-radius: 10px;
    }
    .main {
      width: 100%;
      position: relative;
      .subheader {
        font-size: 1.25rem;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      }
      .help-text {
        text-align: left;
        // font-family: 'Nunito Sans', sans-serif;
        font-family: 'Nunito Sans', sans-serif;
        position: relative;
        padding: 10px;
        font-size: 1.125rem;
        font-weight: 400;
        color: #fff;
      }
    }
  }
}
.vectorjuice {
  color: #9c9c9c;
  opacity: 0.4;
  font-weight: bold;
}
.headline {
  font-size: 3rem;
  color: #fff;
  font-weight: bold;
  text-align: left;
  max-width: 50%;
  font-family: 'Cardo', serif;
}

//image css here
.container {
  position: absolute;
  top: -25px;
  left: 50%;
  max-width: 500px;
  z-index: 2;
}

/* Make the image to responsive */
.the-brain {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.brain-overlay {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0e0e0e;
  opacity: 0;
}

.book-now {
  display: flex;
  width: fit-content;
  position: absolute;
  bottom: 10px;
  margin-left: 10px;
  button {
    padding: 10px;
    font-size: 1.15rem;
    font-weight: bold;
    color: #000000;
    text-decoration: none;
    background-color: Transparent;
    outline: none;
    border: none;
  }
  button:focus {
    outline: 0;
  }
  button:hover {
    cursor: pointer;
  }
}

.get-in-touch {
  display: flex;
  width: fit-content;
  margin-top: 25px;

  button {
    padding: 10px;
    font-size: 1.15rem;
    background-color: black;
    border: solid 2px #9c9c9c;
    font-weight: bold;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
  }
  button:focus {
    outline: 0;
  }
  button:hover {
    cursor: pointer;
  }
}
.bio-text {
  font-size: 1.25rem;
  color: #efefef;
  font-weight: 500;
  text-align: left;
  max-width: 50%;
  margin-top: 20px;
  // font-family: "Ubuntu", sans-serif;
  font-family: 'DM Sans', sans-serif;
  // font-family: 'Nunito Sans', sans-serif;
  // font-family: 'Cardo', serif;
  .link {
    text-decoration: underline;
    // color: #9c9c9c;
    color: #d07919;
    font-weight: 700;
    font-family: 'Nunito Sans', sans-serif;
    // font-family: 'DM Sans', sans-serif;
    // font-family: 'Cardo', serif;
  }
  .link:hover {
    cursor: pointer;
  }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 350px) and (max-device-width: 736px) {
  .main-container {
    margin: 0;
    padding: 10px;
    .headline {
      font-size: 2rem !important;
      color: #fff;
      width: 100%;
      font-weight: bold;
      text-align: center;
      max-width: unset;
    }
  }
  .headline-wrapper {
    // position: absolute;
    margin-top: 50%;
    left: 0;
    z-index: 999;
    padding-left: 10px;
    width: auto;
    .headline {
      font-size: 2rem !important;
      color: #fff;
      width: 100%;
      font-weight: bold;
      text-align: left;
      max-width: unset;
    }
  }

  .bio-text {
    font-size: 1rem;
    color: #9c9c9c;
    font-weight: unset;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-align: left;
    width: 100%;
    max-width: unset;
    margin-top: 20px;
    font-family: 'Ubuntu', sans-serif;
  }
  .second-wrapper {
    margin-top: 100px;
    padding-left: 10px;
    margin-bottom: 0;
    .headline {
      width: 100%;
    }

    .what-text {
      width: 100%;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 1.125rem;
      line-height: 150%;
      margin-top: 20px;
      text-align: center;
      color: #fff;
    }
  }
  .bio-container {
    flex-direction: column;
    width: 100%;
    flex-wrap: unset;
    margin-bottom: 50px;
    // margin: 10px;
    .block {
      width: 100%;
      padding: 15px;
      margin: 10px 0px;
      .block-title {
        font-size: 1.25rem;
        text-align: left;
        width: 100%;
        z-index: 999;
        color: #9c9c9c;
        font-family: 'Nunito Sans', sans-serif;
      }
      .block-text {
        z-index: 999;
        width: 100%;
        font-family: 'Nunito Sans', sans-serif;
        line-height: 150%;
        margin-top: 10px;
        text-align: left;
        font-size: 1rem;
        color: #fff;
        .calendar-link {
          text-decoration: underline;
          color: #d07919;
          font-family: 'Ubuntu', sans-serif;
        }
      }
    }
  }
  //image css here
  .container {
    position: relative;
    /* display: flex; */
    left: 0;
    top: 0;
    margin-top: 40px;
    right: 0;
    width: 100%;
  }

  /* Make the image to responsive */
  .the-brain {
    display: block;
    width: 100%;
    height: auto;
  }

  /* The overlay effect - lays on top of the container and over the image */
  .brain-overlay {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0;
  }
  .ican {
    font-size: 3rem;
    color: #fff;
    font-weight: bold;
    text-align: left;
    max-width: 100%;
  }
  .last-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    .help-block {
      width: 95%;
      margin-top: 25px;
      padding: 20px;
      .top-image {
        // width: 100%;
        height: 50px;
      }
      .main {
        width: 100%;
        position: relative;
        .subheader {
          font-size: 1.25rem;
        }
        .help-text {
          padding: 10px;
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
  }
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}
</style>
