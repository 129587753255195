import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
Vue.use(Vuex);

// const url = "http://localhost:3007/data";
const url = 'https://portfolio-bc8b3.ue.r.appspot.com/data';

export default new Vuex.Store({
	state: {
		blogs: [],
		startups: [],
	},
	getters: {
		blogs: (state) => {
			return state.blogs;
		},
		startups: (state) => {
			return state.startups;
		},
	},
	mutations: {
		setBlogs(state, payload) {
			state.blogs = payload;
		},
		setStartups(state, payload) {
			state.startups = payload;
		},
	},
	actions: {
		async getBlogs({ commit }) {
			let response = await axios
				.get(`${url}/blogs`)
				.then((response) => response.data);
			// console.log('getting posts');
			// let response = await (await butter.post.list({ page: 1, page_size: 10 }))
			// 	.data.data;
			console.log(response);
			if (response && response.length > 0) {
				commit('setBlogs', response);
			}
		},
		async getStartups({ commit }) {
			let response = await axios
				.get(`${url}/startups`)
				.then((response) => response.data);
			// console.log('getting posts');
			// let response = await butter.post.list({ page: 1, page_size: 10 }).data
			// 	.data;
			console.log(response);
			if (response && response.length > 0) {
				response = response.sort((a, b) => {
					return a.position - b.position;
				});
				commit('setStartups', response);
			}
		},
		// async getAbout() {},
	},
	modules: {},
});
