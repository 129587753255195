import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Hello World"
    },
    component: Home
  },
  {
    path: "/services",
    name: "About",
    component: () => import("../views/Services.vue")
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/Blog.vue")
  },
  {
    path: "/startup",
    name: "Startup",
    component: () => import("../views/Startup.vue")
  },
  {
    path: "/post/details/:type/:id",
    name: "Details",
    component: () => import("../views/PostDetails.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
