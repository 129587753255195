import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import vuetify from './plugins/vuetify';
import Vuetify from 'vuetify';
// import Butter from 'buttercms';
// export const butter = Butter('8cfb44697978e2768700c4a0b2026dc1fadc747e');

Vue.config.productionTip = false;
Vue.use(Vuetify);

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
